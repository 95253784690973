<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card class="signup-card">
                <v-toolbar dense flat :color="primaryColor" dark>
                    <v-toolbar-title>Sign up</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <template v-if="!isAuthenticatedReady">
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            Checking status...
                        </p>
                    </v-card-text>
                </template>
                <template v-if="isAuthenticatedReady && isAuthenticated">
                    <v-card-text>
                        <p>
                            You are already signed in as <span class="pseudonym">{{ user.pseudonym }}</span>
                        </p>
                        <p>
                            <router-link :to="{ name: 'user-dashboard' }">Continue to dashboard</router-link>
                        </p>
                        <p>If you want to sign up with a new pseudonym, sign out to continue:</p>
                        <p>
                            <v-btn @click="signout" text>Sign out</v-btn>
                        </p>
                    </v-card-text>
                </template>
                <template v-if="isAuthenticatedReady && !isAuthenticated">
                    <v-form @submit.prevent="submitForm" onSubmit="return false;" @keyup.enter.native.prevent="submitForm" class="mx-4 mt-4 pb-6">
                        <p class="prompt">Select a pseudonym</p>
                        <template v-if="mode === modeInput">
                            <v-text-field v-model="pseudonymInput" ref="pseudonymInput" dense solo color="#3F51B5" hint="We will add some characters at the end to ensure it is unique." placeholder="Pseudonym" :error-messages="inputError">
                                <template #prepend-inner>
                                    <font-awesome-icon :icon="['fas', 'user']" fixed-width/>
                                </template>
                            </v-text-field>
                            <p class="help">
                                A pseudonym is a fictious name. We use a pseudonym instead of your real
                                name or email address to protect your privacy.
                                You will use this name to sign in and we will address you by this name.
                                You can change your pseudonym later.
                            </p>
                        </template>
                        <template v-if="mode === modeConfirm">
                            <v-text-field v-model="pseudonym" dense solo disabled color="#3F51B5" :error-messages="inputError" readonly>
                                <template #prepend-inner>
                                    <font-awesome-icon :icon="['fas', 'user']" fixed-width/>
                                </template>
                                <template #append-outer>
                                    <span style="color: #009900;" class="mt-1"><font-awesome-icon :icon="['fas', 'check-circle']" fixed-width/></span>
                                </template>
                            </v-text-field>
                            <p class="help">
                                This pseudonym is available.
                            </p>
                        </template>
                        <v-row no-gutters justify="center" class="mt-4">
                            <v-btn @click="inputPseudonym" outlined class="mx-2" v-show="mode === modeConfirm">Edit</v-btn>
                            <v-btn @click="reservePseudonym" elevation="4" color="#3F51B5" class="white--text mx-2"  v-show="mode === modeInput">Check it</v-btn>
                            <v-btn @click="register" elevation="4" color="#3F51B5" class="white--text mx-2"  v-show="mode === modeConfirm">Continue</v-btn>
                        </v-row>
                    </v-form>
                </template>
            </v-card>
            <p class="mt-6 grey--text text--darken-2 text-center">Already have an account? <router-link :to="{ name: 'login' }">Sign in</router-link></p>
        </v-col>
    </v-row>
</template>

<style>
.signup-card p.prompt {
    font-size: 1.3em;
    color: #333333;
}
.signup-card p.help {
    font-size: 1.1em;
    color: #555555;
}
span.pseudonym {
    font-weight: bold;
}
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidPseudonym, isValidProductLookupKey } from '@/sdk/input';

const MODE_INPUT = 'input_pseudonym';
const MODE_CONFIRM = 'confirm_pseudonym';

export default {
    components: {
    },
    data: () => ({
        pseudonym: '', // the final pseudonym we will register
        pseudonymInput: '', // what the user typed in
        mode: null, // input_pseudonym, confirm_pseudonym
        product: null,
        submitTimestamp: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
            account: (state) => state.account,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        modeInput() {
            return MODE_INPUT;
        },
        modeConfirm() {
            return MODE_CONFIRM;
        },
    },
    watch: {
        focus() {
            // this.redirect();
            if (this.mode === MODE_INPUT) {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('pseudonymInput'); }, 1);
                });
            }
        },
        mode() {
            this.prepareView();
        },
    },
    methods: {
        prepareView() {
            if (this.mode === MODE_INPUT) {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('pseudonymInput'); }, 1);
                });
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            // this.redirect = null;
            // this.verificationExpires = null;
        },
        inputPseudonym() {
            this.pseudonym = '';
            this.mode = MODE_INPUT;
        },
        async reservePseudonym() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.resetErrors();
                if (typeof this.pseudonymInput !== 'string' || this.pseudonymInput.trim().length === 0 || !isValidPseudonym(this.pseudonymInput)) {
                    this.inputError = 'Please select a pseudonym';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { reservePseudonym: true });
                const request = {
                    pseudonym: this.pseudonymInput,
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.reservePseudonym(request);
                console.log(`reservePseudonym response ${JSON.stringify(response)}`);
                if (response?.type === 'status' && response?.status?.reserved) {
                    this.pseudonym = response.status.reserved; // with automatically generated suffix
                    this.mode = MODE_CONFIRM; // show a confirmation and let user edit (return to input mode)
                } else {
                    this.inputError = 'Please select a different pseudonym';
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { reservePseudonym: false });
                // this.isViewReady = true;
            }
        },
        async register() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.resetErrors();
                if (typeof this.pseudonym !== 'string' || this.pseudonym.trim().length === 0 || !isValidPseudonym(this.pseudonym)) {
                    this.inputError = 'Please select a pseudonym';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    this.mode = MODE_INPUT;
                    return;
                }
                this.$store.commit('loading', { signup: true });
                const request = {
                    pseudonym: this.pseudonym,
                    product: this.product, // may have a value if user arrived here by selecting a something on the pricing page
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.type === 'status' && response.status?.authenticated) {
                    console.log('refreshing state');
                    await this.$store.dispatch('refresh');
                    console.log('redirecting to user-connect-xentri');
                    this.$router.push({ name: 'user-connect-xentri', query: { product: this.product } });
                    return;
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { signup: false });
                // this.isViewReady = true;
            }
        },
        async submitForm() {
            if (this.mode === MODE_INPUT) {
                await this.reservePseudonym();
            } else if (this.mode === MODE_CONFIRM) {
                await this.register();
            } else {
                console.log(`form submitted on mode: ${this.mode}`);
            }
        },
        async signout() {
            await this.$store.dispatch('logout');
        },
    },
    mounted() {
        // TODO: replace the 'mode' query parameter here with a 'confirm' parameter, or not at all;  the 'mode' parameter is for the main signup activity
        if (!this.$route.query.mode) {
            this.mode = MODE_INPUT;
        } else {
            this.mode = this.$route.query.mode;
        }

        if (this.$route.query.product && isValidProductLookupKey(this.$route.query.product)) {
            this.product = this.$route.query.product;
        }
    },
};
</script>
